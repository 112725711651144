import React from "react";
import Script from "next/script";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getStaticPageContent, Page } from "components";
import { GetStaticPropsContext } from "next";
import serverSideTranslations from "utils/serverSideTranslations";
import { parseISO } from "date-fns";
import { cms } from "utils/api";
import { getCountriesWithPublishedTrips } from "utils/api/site2";
import styles from "../region.module.scss";
import {
  LocationCard,
  CardLayout,
  PartnerNotice,
  Breadcrumbs,
  BlogCard,
  ReviewsList,
  ImagePullout,
} from "@travellocal/ui";
import { activeCountriesType, RegionsReviewType } from "utils/types";
import { translateRoute } from "utils/localisedRouteHelpers";

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (context.locale === "default") {
    return {
      notFound: true,
    };
  }

  const pageProps = await getStaticPageContent(context);
  const currentLocale =
    pageProps.initialAppState.locale ?? pageProps.initialAppState.detectedLocale;
  const regionProfileUrlFragment = context.params?.regionProfile as string;
  const regionId = await cms.getRegionIdFromRegionProfileSlug(regionProfileUrlFragment);
  const regionProfilesResult = await cms.getRegionProfile(
    regionProfileUrlFragment,
    [currentLocale],
    regionId
  );

  const regionProfile = regionProfilesResult?.regionProfiles?.nodes[0];
  const partnerNoticeImages =
    regionProfilesResult?.region?.regionTaxonomy?.components?.partnernoticeImages;

  if (!regionProfile) {
    return {
      notFound: true,
    };
  }

  // get site 2 countries
  const site2Countries = await getCountriesWithPublishedTrips(currentLocale);

  // get related countries from the Region terms
  const regionTermIds =
    regionProfile.terms.nodes.map((term) => (term as { termTaxonomyId?: number }).termTaxonomyId) ||
    [];
  // mapping the IDs into strings for graphql
  const relatedCountries = await cms.getCountriesByRegion(regionTermIds.map(String), [
    currentLocale,
  ]);
  const globalContents = await cms.getGlobalContent([currentLocale]);

  // Sort countries alphabetically
  const sortedRelatedCountries = relatedCountries.nodes.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  // Find site 2 countries matching the slug with the urlFragment. Return the href/linkToClp
  const activeCountries: activeCountriesType = [];

  sortedRelatedCountries.map((country) => {
    const urlFragment = country.meta.urlFragment;
    const matchingCountry = site2Countries.find(
      (site2country) => site2country.slug === urlFragment
    );
    const linkToCLP = matchingCountry ? matchingCountry.href : null;
    if (linkToCLP) {
      activeCountries.push({
        key: country.id,
        title: country.meta.pageTitle || country.title,
        color: country.country.countrycolour,
        image: {
          sourceUrl: country.country.countryheroimage.sourceUrl,
          altText: country.country.countryheroimage.altText,
        },
        href: linkToCLP,
      });
    }
  });

  // Get region taxonomy content
  const regionTaxContent = {
    partnerNotice: (partnerNoticeImages || []).map((image) => ({
      alt: image.ltoImage?.altText || null,
      url: image.ltoImage?.sourceUrl || null,
    })),
  };

  const regionsToAlternates =
    regionProfilesResult.regionProfiles.nodes[0].regions.nodes[0].regionProfiles.nodes;

  const alternatePages = regionsToAlternates?.map((region) => {
    return {
      locale: region.locales.nodes[0].name.toLowerCase(),
      url: translateRoute(region.locales.nodes[0].name.toLowerCase(), "regionProfileBySlug", {
        slug: region.meta.urlFragment,
      }),
    };
  });

  return {
    revalidate: 600,
    props: {
      ...(await serverSideTranslations(currentLocale)),
      pageProps,
      regionProfile,
      globalContents,
      regionTitle: regionProfile?.meta?.pageTitle || regionProfile?.title,
      regionTaxContent,
      activeCountries,
      alternatePages,
    },
  };
};

type Props = Omit<PropsOf<typeof getStaticProps>, "_nextI18Next">;

export const getStaticPaths = () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

const RegionProfilePage = ({
  pageProps,
  regionProfile,
  globalContents,
  regionTitle,
  regionTaxContent,
  activeCountries,
  alternatePages,
}: Props) => {
  const { t } = useTranslation();
  const locale = pageProps.initialAppState.locale;
  const canonicalUrl = translateRoute(locale, "regionProfileBySlug", {
    slug: regionProfile.meta.urlFragment,
  });

  const regionProps = {
    title: regionTitle,
    intro: regionProfile?.regionProfile?.regionIntro,
    trustedShops:
      pageProps.initialAppState.locale === "en"
        ? t(
            "web:TrustedShops_widget_stars",
            `<etrusted-widget data-etrusted-widget-id="wdg-5ba34ee0-fc4d-41f9-89ad-a35b652fbe0d"></etrusted-widget>`
          )
        : pageProps.initialAppState.locale === "de"
          ? t(
              "web:TrustedShops_widget_stars",
              `<etrusted-widget data-etrusted-widget-id="wdg-3ed2bea3-be01-4553-9337-d12eadcb0fcf"></etrusted-widget>`
            )
          : null,
    countries: activeCountries,
    partnerNotice: {
      images: regionTaxContent.partnerNotice,
      heading: t("web:RegionProfile_partnerNotice_heading", "We can’t wait to welcome you!"),
      content: t("web:RegionProfile_partnerNotice_content", `Visit {{region}}`, {
        region: regionTitle,
      }),
    },
    reviews: {
      reviews:
        regionProfile?.regionProfile?.components?.reviewslistReviews ||
        globalContents?.nodes[0]?.globalContent?.reviewslistReviews,
      title:
        regionProfile?.regionProfile?.components?.reviewslistTitle ||
        globalContents?.nodes[0]?.globalContent?.reviewslistTitle,
    },
    articles: regionProfile?.regionProfile?.components?.articlesArticles,
    imagePullout: {
      heading: t(
        "web:RegionPage_ImagePullout_heading",
        "We're reimagining a fairer way to visit {{region}}",
        {
          region: regionTitle,
        }
      ),
      text: t(
        "web:RegionPage_ImagePullout_text",
        "Travel that benefits all; our environment, ecosystems, local partners, and travelers."
      ),
      ctaTitle: t("web:RegionPage_ImagePullout_ctaTitle", "Find out how"),
      ctaUrl: t("routes:howItWorks", "/how-it-works"),
      imageSrc: regionProfile?.regionProfile?.components?.imagepulloutImage?.sourceUrl,
    },
  };

  return (
    <Page
      title={regionProps.title ? `${regionProps.title} | TravelLocal` : "TravelLocal"}
      alternatePages={alternatePages}
      meta={{
        canonicalUrl,
        description: regionProfile.meta?.searchDescription,
        imageSrc: regionProfile.regionProfile?.components?.imagepulloutImage?.sourceUrl,
        locale: regionProfile.locales.nodes[0].slug,
        title: regionProfile.meta?.pageTitle || regionTitle,
      }}
      {...pageProps}>
      <Script
        src="https://integrations.etrusted.com/applications/widget.js/v2"
        strategy="lazyOnload"
      />
      <div
        className={classNames("tl-region", {
          "mb-8": !!regionProps.imagePullout.imageSrc,
        })}>
        <section className="tl-region__intro-wrapper container my-6">
          <div className="columns is-marginless is-centered">
            <div className="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen">
              <Breadcrumbs
                className="tl-region__breadcrumbs mb-5 has-text-centered"
                items={[
                  {
                    label: t("common:pageName_index", "Home"),
                    href: t("routes:index", "/"),
                  },
                  {
                    label: t("common:pageName_destinations", "Destinations"),
                    href: t("routes:destinations", "/destinations"),
                  },
                  { label: regionProps.title },
                ]}
              />

              <h1 className="title is-size-2 is-size-1-tablet mb-5 has-text-centered">
                {t("web:RegionProfile_title", "Visit {{regionProfile}}", {
                  regionProfile: regionProps.title,
                })}
              </h1>

              {regionProps.intro && (
                <p className="is-size-5 has-text-centered">{regionProps.intro}</p>
              )}
              {regionProps.trustedShops && (
                <div className="trustedshops">
                  <div dangerouslySetInnerHTML={{ __html: regionProps.trustedShops }}></div>
                </div>
              )}
            </div>
          </div>
        </section>

        {regionProps.countries.length > 0 && (
          <section className="tl-region__countries mb-4">
            <div className="container overflow-iphone-fix">
              <CardLayout layout="grid">
                {activeCountries.map((foundCountry, i) => (
                  <LocationCard
                    key={foundCountry.key}
                    title={foundCountry.title}
                    color={foundCountry.color}
                    image={foundCountry.image}
                    href={foundCountry.href}
                    imagePriority={i < 5}
                  />
                ))}
              </CardLayout>
            </div>
          </section>
        )}

        {regionProps.partnerNotice.images && (
          <section
            className={classNames(styles.partnerNotice, {
              [styles.bgWhite]: !regionProps.reviews.reviews && !regionProps.articles,
            })}>
            <div className="container">
              <div className="columns is-marginless is-centered">
                <div className="column is-12-mobile is-10-tablet is-9-desktop is-7-widescreen">
                  <div className={"tl-region__partnerNotice mb-6"}>
                    <PartnerNotice
                      heading={regionProps.partnerNotice.heading}
                      content={regionProps.partnerNotice.content}
                      items={regionProps.partnerNotice.images}
                      stamp="on"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {regionProps.reviews.reviews && (
          <section className={classNames(styles.reviews, "pt-6 pb-6")}>
            <div className="container">
              <ReviewsList reviews={(regionProps.reviews.reviews as RegionsReviewType) || []} />
            </div>
          </section>
        )}

        {regionProps.articles && (
          <section
            className={classNames(styles.articles, "pt-6", {
              "pb-8": !regionProps.imagePullout.imageSrc,
              [styles.bgSage]: !regionProps.imagePullout.imageSrc,
            })}>
            <div className="container">
              <div className="columns is-centered">
                {Array.isArray(regionProps.articles) &&
                  regionProps.articles?.slice(0, 2).map((article) => {
                    if (!article.article) {
                      return null;
                    }
                    return (
                      <div
                        className="column is-12-mobile is-6-tablet is-5-widescreen"
                        key={article.article.title}>
                        <BlogCard
                          size="hero"
                          title={article.article.title}
                          url={t("routes:articleBySlug", {
                            slug: article.article.meta.urlFragment,
                          })}
                          target="_self"
                          image={article.article.featuredImage?.node?.sourceUrl}
                          date={article.article.date ? parseISO(article.article.date) : undefined}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        )}

        {regionProps.imagePullout.imageSrc && (
          <section
            className={classNames({
              "mt-7 mb-8": !regionProps.articles && !regionProps.reviews.reviews,
              "my-8": regionProps.articles || regionProps.reviews.reviews,
            })}>
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12-tablet is-10-widescreen">
                  <ImagePullout
                    heading={regionProps.imagePullout.heading}
                    text={regionProps.imagePullout.text}
                    callToAction={{
                      target: "_self",
                      title: regionProps.imagePullout.ctaTitle,
                      url: regionProps.imagePullout.ctaUrl,
                    }}
                    bgSrc={regionProps.imagePullout.imageSrc}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </Page>
  );
};

export default RegionProfilePage;
